export const pools = [
  {
    pid: 0,
    stakedTokenName: "ERP",
    stakedTokenSymbol: "ERP",
    rewardTokenName:"ERP",
    stakedTokenDecimals: 18,
    tokenAddress: "0x0a0e3bfD5a8cE610E735D4469Bc1b3b130402267",
    poolAddress: "0xEB1022beA328dD83336465610eB97390e6911775",
    getUrl:"https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x0a0e3bfD5a8cE610E735D4469Bc1b3b130402267",
    weight: 0.6,
    toFixed: 2
  },
  {
    pid: 1,
    stakedTokenName: "ERP:USDC LP",
    stakedTokenSymbol: "ERP:USDC LP",
    rewardTokenName:"ERP",
    stakedTokenDecimals: 18,
    tokenAddress: "0xB75767451fc277211a480Ff3712Eac6B0fa23b13",
    poolAddress: "0xb73659906FE427650231A1519f2DD191BD2A06f6",
    getUrl:"https://app.uniswap.org/#/add/v2/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/0x0a0e3bfD5a8cE610E735D4469Bc1b3b130402267?chain=mainnet",
    weight: 0.4,
    toFixed: 8
  },
];
