import React, { memo, useEffect } from 'react';
import { useFetchPrice } from '../../price/fetchPrice';
import { useConnectWallet } from 'features/home/redux/connectWallet';
import logo from "../../../assets/img/ERP.png";
import { addToken } from "./addTokens"


const TokenPrice = () => {
  const { web3 } = useConnectWallet();

  const { fetchPrice, priceData } = useFetchPrice();

  useEffect(() => {
    if (web3) {
      fetchPrice({ web3 });

      const id = setInterval(() => {
        fetchPrice({ web3 });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3]);

  return (
    <>
      <img
        alt="ERP"
        src={logo}
        onClick={addToken}
        style={{ height: '20px', marginRight: '5px', marginLeft: '50px', cursor: 'pointer'}}
      />
      <span style={{ fontWeight: 800, color: "white" }}>${priceData ? priceData.toFixed(2) : 'Loading'}</span>
    </> 
  );
};

export default memo(TokenPrice);
