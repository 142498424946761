import axios from "axios";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from './constants';
import { uniswapV2PairABI, contracts } from 'features/configure';
import { convertAmountFromRawNumber } from "../helpers/bignumber";
import _ from "lodash";

export function fetchPrice({ web3 }) {
  return dispatch => {
    dispatch({
      type: FETCH_PRICE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {

    const uniswapPairContract = new web3.eth.Contract(uniswapV2PairABI, contracts.uniswapLpPairAddress.address);

      Promise.all([
        axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids: 'entropyfi', vs_currencies: 'usd' },
        }),
        uniswapPairContract.methods.getReserves().call(),
        uniswapPairContract.methods.totalSupply().call(),
        axios.get(`https://analytics.vault.inc/api/stats/priceHistory?name=entropyfi`)
      ]).then(data => {
        dispatch({
          type: FETCH_PRICE_SUCCESS,
          data
        });
        resolve(data);
      })
        .catch(error => {
          dispatch({
            type: FETCH_PRICE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceData, lpData, chart } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceData: state.price.priceData, 
    lpData: state.price.lpData,
    chart: state.price.chart,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceData, 
    lpData,
    chart
  };
}

export function reducer(state = { fetchPricePending: false, priceData: {}, lpData: {},chart:[] }, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_SUCCESS:
      const priceData = action.data[0].data['entropyfi'].usd;
      let chart = action.data[3].data

      let latest = _.last(chart);
      
      let modifiedLatest = (_.dropRight(latest));
      modifiedLatest.push(priceData);
      
      
      let modifiedChart = (_.dropRight(chart));
      modifiedChart.push(modifiedLatest);

  

      const lpData = {
        tokenReserve: convertAmountFromRawNumber(action.data[1]._reserve0),
        usdcReserve: convertAmountFromRawNumber(action.data[1]._reserve1, 6),
        totalSupply: convertAmountFromRawNumber(action.data[2])
      }
      return {
        ...state,
        priceData: priceData,
        chart:modifiedChart,
        lpData: lpData,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    default:
      return state;
  }
}
