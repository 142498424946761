export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  liquidityMiningManager: {
    address: "0x6BBB40691d93259bcd953080341C29D13F403814",
  },
  escrowedReward: {
    address: "0x799108A5932Ef81fbB6c4531A2A5E648FE29dCed",
  },
  view: {
    address: "0x4737a2e1C815e0068e51c061C05c1C3Ac4270871",
  },
  singleTokenPool: {
    address: "0xEB1022beA328dD83336465610eB97390e6911775",
  },
  lpPool: {
    address: "0xb73659906FE427650231A1519f2DD191BD2A06f6",
  },
  uniswapLpPairAddress : {
    address: "0xB75767451fc277211a480Ff3712Eac6B0fa23b13"
  }
};
