export {
  singleTokenStakingABI,
  erc20ABI,
  escrowedRewardABI,
  liquidityMiningManagerABI,
  lpTokenStakingABI,
  viewABI,
  uniswapV2PairABI
} from "./abi";
export { tokens } from "./tokens";
export { pools } from "./pools";
export { contracts } from "./contracts";
export const websiteUrl = {};
export const apiUrl = "http://localhost:21666";
